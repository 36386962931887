(function ($) {

//placing Cookies
    $('window').ready(function () {
        var cookieValueEmail = document.cookie.replace(/(?:(?:^|.*;\s*)email\s*\=\s*([^;]*).*$)|^.*$/, '$1');
        var cookieValueName = document.cookie.replace(/(?:(?:^|.*;\s*)naam\s*\=\s*([^;]*).*$)|^.*$/, '$1');

        $('.email-cookie').val(cookieValueEmail);
        $('.name-cookie').val(cookieValueName);

    });

    $('document').ready(function () {

        'use strict';

        //Setting cookies
        $(function () {
            $('.email-cookie').change(function () {
                var emailValue = (this).value;
                if (emailValue != 0) {
                    document.cookie = 'email=' + emailValue + '';
                }
            });

            $('.name-cookie').change(function () {
                var nameValue = (this).value;
                if (nameValue != 0) {
                    document.cookie = 'naam=' + nameValue + '';
                }
            });
        })

        // Setting correct pag
        if (window.location.hash) {
            var targetPage = location.hash;
            console.log(targetPage)
        }

        // Going trough multiple forms
        $(function () {
            $('.start-button, .next-question').click(function () {
                var parent = $(this).closest('.form-fader');
                parent.fadeOut().promise().done(function () {
                    parent.next('.form-fader').fadeIn();
                    $('.logo-top').fadeIn();
                });
            });
        });

        // Responsiveness
        if (window.matchMedia('(max-width: 1024px)').matches) {
            $('.languages').remove().insertAfter('.start .right');
        }

        // On change radio button (for checking)
        $(function () {
            $('input[type="radio"]').change(function () {

                // Variables
                var parent = $(this).closest('.question-holder'),
                    rightTarget = $(parent).find('.correct-answer'),
                    wrongTarget = $(parent).find('.incorrect-answer'),
                    nextButton = $(parent).find('a.next-question'),
                    status = false,
                    inputs = $(parent).find('.inputs input');

                // Checking if answer is true, else etc.
                if ($(this).is(':checked')) {
                    if ($(this).hasClass('ra')) {

                        status = true;
                        $(wrongTarget).fadeOut().promise().done(function () {
                            rightTarget.fadeIn();
                            nextButton.fadeIn();
                            inputs.prop('disabled', true);
                        });

                    } else {
                        wrongTarget.addClass('visible');
                        wrongTarget.fadeIn();
                        status = false;
                    }
                }
            });
        });
    });
})(jQuery);